import { AxiosError } from "axios"

import { API_DEFAULT } from "src/constants/minutApi"
import { setStoredAuthorization } from "src/data/auth/auth"
import { IAuthorization } from "src/data/user/user"
import { ErrorService } from "src/utils/ErrorService"
import { debug } from "src/utils/logger"
import { minutApiHttpClient } from "src/utils/minutApiHttpClient"
import { TMaybeAxiosError } from "src/utils/tsUtil"

const authenticate = async (email: string, password: string) => {
  // eslint-disable-next-line no-useless-catch
  try {
    const response = await minutApiHttpClient.post(
      `${API_DEFAULT}/oauth/token`,
      {
        client_id: import.meta.env.REACT_APP_CLIENT_ID,
        client_secret: import.meta.env.REACT_APP_CLIENT_SECRET,
        username: email,
        password,
        grant_type: "password",
      }
    )

    const { data } = response

    // Save authentication tokens, used e.g. for auto-sign in
    setStoredAuthorization(data)

    return data
  } catch (err) {
    throw err
  }
}

/**
 * Sign in with username & password.
 * Will be replaced with centralized auth service in the future.
 */
export async function passwordSignin({
  username,
  password,
  onError,
  onSignedIn,
  onSettled,
}: {
  username: string
  password: string
  onError?: (e: TMaybeAxiosError) => void
  onSignedIn: ({ authorization }: { authorization: IAuthorization }) => void
  onSettled?: () => void
}) {
  debug.log("signing in: username/pw flow")

  try {
    const result = await authenticate(username, password)
    const authorization: IAuthorization = result

    onSignedIn?.({ authorization })
  } catch (e) {
    const error = e as AxiosError
    if (error.response?.status === 403) {
      // noop
    } else {
      // Log exception if it was not due to credentials being wrong
      ErrorService.captureException(error)
    }
    onError?.(error)
  } finally {
    onSettled?.()
  }
}
